module.exports = {
  // PROD
  paypalAccountClientId: 'ASh5saCZllmjN_XurjPpktx9CLENu-Bh35CBda0mF1qF8xv5SCHzvHW8Fp5gUiol-bxBsA5gNsvkz7oN',
  // DEV
  // paypalAccountClientId: 'test',
  breakpoints: {
    tablet: '512px',
    desktop: '1170px',
    headerExpand: '992px'
  }
}
